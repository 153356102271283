<template>
  <NotificationBase :notification :compact :icon="{ name: 'support-solid' }">
    <template #subject>
      {{ t('notifications.defaultSubject') }}
    </template>
    <p>
      {{ notification.data.text }}
    </p>
  </NotificationBase>
</template>

<script setup lang="ts">
import NotificationBase from '../NotificationBase.vue'
import type { AdminNotification } from '../../../../types'

const { t } = useI18n()

interface Props {
  notification: AdminNotification
  compact?: boolean
}

const { notification, compact = false } = defineProps<Props>()
</script>
